export const entityToActionId: { [key: string]: string[] } = {
  'data/entities/projectiles/bomb.xml': ['BOMB'],
  'data/entities/projectiles/deck/light_bullet.xml': [
    'LIGHT_BULLET',
    'LIGHT_BULLET_TRIGGER',
    'LIGHT_BULLET_TIMER',
  ],
  'data/entities/projectiles/deck/light_bullet_blue.xml': [
    'LIGHT_BULLET_TRIGGER_2',
  ],
  'data/entities/projectiles/deck/bullet.xml': [
    'BULLET',
    'BULLET_TRIGGER',
    'BULLET_TIMER',
  ],
  'data/entities/projectiles/deck/bullet_heavy.xml': [
    'HEAVY_BULLET',
    'HEAVY_BULLET_TRIGGER',
    'HEAVY_BULLET_TIMER',
  ],
  'data/entities/projectiles/deck/light_bullet_air.xml': ['AIR_BULLET'],
  'data/entities/projectiles/deck/bullet_slow.xml': [
    'SLOW_BULLET',
    'SLOW_BULLET_TRIGGER',
    'SLOW_BULLET_TIMER',
  ],
  'data/entities/projectiles/deck/black_hole.xml': [
    'BLACK_HOLE',
    'BLACK_HOLE_DEATH_TRIGGER',
  ],
  'data/entities/projectiles/deck/black_hole_big.xml': ['BLACK_HOLE_BIG'],
  'data/entities/projectiles/deck/black_hole_giga.xml': ['BLACK_HOLE_GIGA'],
  'data/entities/projectiles/deck/tentacle_portal.xml': ['TENTACLE_PORTAL'],
  'data/entities/projectiles/deck/spitter.xml': ['SPITTER', 'SPITTER_TIMER'],
  'data/entities/projectiles/deck/spitter_tier_2.xml': [
    'SPITTER_TIER_2',
    'SPITTER_TIER_2_TIMER',
  ],
  'data/entities/projectiles/deck/spitter_tier_3.xml': [
    'SPITTER_TIER_3',
    'SPITTER_TIER_3_TIMER',
  ],
  'data/entities/projectiles/deck/bubbleshot.xml': [
    'BUBBLESHOT',
    'BUBBLESHOT_TRIGGER',
  ],
  'data/entities/projectiles/deck/disc_bullet.xml': ['DISC_BULLET'],
  'data/entities/projectiles/deck/disc_bullet_big.xml': ['DISC_BULLET_BIG'],
  'data/entities/projectiles/deck/disc_bullet_bigger.xml': [
    'DISC_BULLET_BIGGER',
  ],
  'data/entities/projectiles/deck/bouncy_orb.xml': [
    'BOUNCY_ORB',
    'BOUNCY_ORB_TIMER',
  ],
  'data/entities/projectiles/deck/rubber_ball.xml': ['RUBBER_BALL'],
  'data/entities/projectiles/deck/arrow.xml': ['ARROW'],
  'data/entities/projectiles/deck/pollen.xml': ['POLLEN'],
  'data/entities/projectiles/deck/lance.xml': ['LANCE'],
  'data/entities/projectiles/deck/rocket.xml': ['ROCKET'],
  'data/entities/projectiles/deck/rocket_tier_2.xml': ['ROCKET_TIER_2'],
  'data/entities/projectiles/deck/rocket_tier_3.xml': ['ROCKET_TIER_3'],
  'data/entities/projectiles/deck/grenade.xml': ['GRENADE', 'GRENADE_TRIGGER'],
  'data/entities/projectiles/deck/grenade_tier_2.xml': ['GRENADE_TIER_2'],
  'data/entities/projectiles/deck/grenade_tier_3.xml': ['GRENADE_TIER_3'],
  'data/entities/projectiles/deck/grenade_anti.xml': ['GRENADE_ANTI'],
  'data/entities/projectiles/deck/grenade_large.xml': ['GRENADE_LARGE'],
  'data/entities/projectiles/deck/mine.xml': ['MINE', 'MINE_DEATH_TRIGGER'],
  'data/entities/projectiles/deck/pipe_bomb.xml': [
    'PIPE_BOMB',
    'PIPE_BOMB_DEATH_TRIGGER',
  ],
  'data/entities/projectiles/deck/exploding_deer.xml': ['EXPLODING_DEER'],
  'data/entities/projectiles/deck/duck.xml': ['EXPLODING_DUCKS'],
  'data/entities/projectiles/deck/worm_shot.xml': ['WORM_SHOT'],
  'data/entities/projectiles/deck/bomb_detonator.xml': ['BOMB_DETONATOR'],
  'data/entities/projectiles/deck/laser.xml': ['LASER'],
  'data/entities/projectiles/deck/megalaser_beam.xml': ['MEGALASER'],
  'data/entities/projectiles/deck/megalaser.xml': ['MEGALASER'],
  'data/entities/projectiles/deck/lightning.xml': ['LIGHTNING'],
  'data/entities/projectiles/deck/ball_lightning.xml': ['BALL_LIGHTNING'],
  'data/entities/projectiles/deck/orb_laseremitter.xml': ['LASER_EMITTER'],
  'data/entities/projectiles/deck/orb_laseremitter_four.xml': [
    'LASER_EMITTER_FOUR',
  ],
  'data/entities/projectiles/deck/orb_laseremitter_cutter.xml': [
    'LASER_EMITTER_CUTTER',
  ],
  'data/entities/projectiles/deck/digger.xml': ['DIGGER'],
  'data/entities/projectiles/deck/powerdigger.xml': ['POWERDIGGER'],
  'data/entities/projectiles/deck/chainsaw.xml': ['CHAINSAW'],
  'data/entities/projectiles/deck/luminous_drill.xml': [
    'LUMINOUS_DRILL',
    'LASER_LUMINOUS_DRILL',
  ],
  'data/entities/projectiles/deck/tentacle.xml': ['TENTACLE', 'TENTACLE_TIMER'],
  'data/entities/projectiles/deck/heal_bullet.xml': ['HEAL_BULLET'],
  'data/entities/projectiles/deck/spiral_shot.xml': ['SPIRAL_SHOT'],
  'data/entities/projectiles/deck/magic_shield_start.xml': ['MAGIC_SHIELD'],
  'data/entities/projectiles/deck/big_magic_shield_start.xml': [
    'BIG_MAGIC_SHIELD',
  ],
  'data/entities/projectiles/deck/chain_bolt.xml': ['CHAIN_BOLT'],
  'data/entities/projectiles/deck/fireball.xml': ['FIREBALL'],
  'data/entities/projectiles/deck/meteor.xml': ['METEOR'],
  'data/entities/projectiles/deck/flamethrower.xml': ['FLAMETHROWER'],
  'data/entities/projectiles/deck/iceball.xml': ['ICEBALL'],
  'data/entities/projectiles/deck/slime.xml': ['SLIMEBALL'],
  'data/entities/projectiles/darkflame.xml': ['DARKFLAME'],
  'data/entities/projectiles/deck/rocket_player.xml': ['MISSILE'],
  'data/entities/projectiles/deck/machinegun_bullet.xml': ['FUNKY_SPELL'],
  'data/entities/projectiles/deck/pebble_player.xml': ['PEBBLE'],
  'data/entities/projectiles/deck/tnt.xml': ['DYNAMITE'],
  'data/entities/projectiles/deck/glitter_bomb.xml': ['GLITTER_BOMB'],
  'data/entities/projectiles/deck/buckshot_player.xml': ['BUCKSHOT'],
  'data/entities/projectiles/deck/freezing_gaze_beam.xml': ['FREEZING_GAZE'],
  'data/entities/projectiles/deck/glowing_bolt.xml': ['GLOWING_BOLT'],
  'data/entities/projectiles/deck/spore_pod.xml': ['SPORE_POD'],
  'data/entities/projectiles/deck/glue_shot.xml': ['GLUE_SHOT'],
  'data/entities/projectiles/bomb_holy.xml': ['BOMB_HOLY'],
  'data/entities/projectiles/bomb_holy_giga.xml': ['BOMB_HOLY_GIGA'],
  'data/entities/projectiles/propane_tank.xml': ['PROPANE_TANK'],
  'data/entities/projectiles/bomb_cart.xml': ['BOMB_CART'],
  'data/entities/projectiles/orb_cursed.xml': ['CURSED_ORB'],
  'data/entities/projectiles/orb_expanding.xml': ['EXPANDING_ORB'],
  'data/entities/projectiles/deck/crumbling_earth.xml': ['CRUMBLING_EARTH'],
  'data/entities/projectiles/deck/rock.xml': ['SUMMON_ROCK'],
  'data/entities/items/pickup/egg_hollow.xml': ['SUMMON_HOLLOW_EGG'],
  'data/entities/projectiles/deck/tntbox.xml': ['TNTBOX'],
  'data/entities/projectiles/deck/tntbox_big.xml': ['TNTBOX_BIG'],
  'data/entities/projectiles/deck/swarm_fly.xml': ['SWARM_FLY'],
  'data/entities/projectiles/deck/swarm_firebug.xml': ['SWARM_FIREBUG'],
  'data/entities/projectiles/deck/swarm_wasp.xml': ['SWARM_WASP'],
  'data/entities/projectiles/deck/friend_fly.xml': ['FRIEND_FLY'],
  'data/entities/projectiles/deck/acidshot.xml': ['ACIDSHOT'],
  'data/entities/projectiles/thunderball.xml': ['THUNDERBALL'],
  'data/entities/projectiles/deck/firebomb.xml': ['FIREBOMB'],
  'data/entities/projectiles/chunk_of_soil.xml': ['SOILBALL'],
  'data/entities/projectiles/deck/death_cross.xml': ['DEATH_CROSS'],
  'data/entities/projectiles/deck/death_cross_big.xml': ['DEATH_CROSS_BIG'],
  'data/entities/projectiles/deck/infestation.xml': ['INFESTATION'],
  'data/entities/projectiles/deck/wall_horizontal.xml': ['WALL_HORIZONTAL'],
  'data/entities/projectiles/deck/wall_vertical.xml': ['WALL_VERTICAL'],
  'data/entities/projectiles/deck/wall_square.xml': ['WALL_SQUARE'],
  'data/entities/projectiles/deck/temporary_wall.xml': ['TEMPORARY_WALL'],
  'data/entities/projectiles/deck/temporary_platform.xml': [
    'TEMPORARY_PLATFORM',
  ],
  'data/entities/projectiles/deck/purple_explosion_field.xml': [
    'PURPLE_EXPLOSION_FIELD',
  ],
  'data/entities/projectiles/deck/delayed_spell.xml': ['DELAYED_SPELL'],
  'data/entities/projectiles/deck/long_distance_cast.xml': [
    'LONG_DISTANCE_CAST',
  ],
  'data/entities/projectiles/deck/teleport_cast.xml': ['TELEPORT_CAST'],
  'data/entities/projectiles/deck/super_teleport_cast.xml': [
    'SUPER_TELEPORT_CAST',
  ],
  'data/entities/projectiles/deck/mist_radioactive.xml': ['MIST_RADIOACTIVE'],
  'data/entities/projectiles/deck/mist_alcohol.xml': ['MIST_ALCOHOL'],
  'data/entities/projectiles/deck/mist_slime.xml': ['MIST_SLIME'],
  'data/entities/projectiles/deck/mist_blood.xml': ['MIST_BLOOD'],
  'data/entities/projectiles/deck/circle_fire.xml': ['CIRCLE_FIRE'],
  'data/entities/projectiles/deck/circle_acid.xml': ['CIRCLE_ACID'],
  'data/entities/projectiles/deck/circle_oil.xml': ['CIRCLE_OIL'],
  'data/entities/projectiles/deck/circle_water.xml': ['CIRCLE_WATER'],
  'data/entities/projectiles/deck/material_water.xml': ['MATERIAL_WATER'],
  'data/entities/projectiles/deck/material_oil.xml': ['MATERIAL_OIL'],
  'data/entities/projectiles/deck/material_blood.xml': ['MATERIAL_BLOOD'],
  'data/entities/projectiles/deck/material_acid.xml': ['MATERIAL_ACID'],
  'data/entities/projectiles/deck/material_cement.xml': ['MATERIAL_CEMENT'],
  'data/entities/projectiles/deck/teleport_projectile.xml': [
    'TELEPORT_PROJECTILE',
  ],
  'data/entities/projectiles/deck/teleport_projectile_short.xml': [
    'TELEPORT_PROJECTILE_SHORT',
  ],
  'data/entities/projectiles/deck/teleport_projectile_static.xml': [
    'TELEPORT_PROJECTILE_STATIC',
  ],
  'data/entities/projectiles/deck/swapper.xml': ['SWAPPER_PROJECTILE'],
  'data/entities/projectiles/deck/teleport_projectile_closer.xml': [
    'TELEPORT_PROJECTILE_CLOSER',
  ],
  'data/entities/projectiles/deck/nuke.xml': ['NUKE'],
  'data/entities/projectiles/deck/nuke_giga.xml': ['NUKE_GIGA'],
  'data/entities/projectiles/deck/wand_ghost_player.xml': ['SUMMON_WANDGHOST'],
  'data/entities/particles/image_emitters/wand_effect.xml': [
    'SUMMON_WANDGHOST',
  ],
  'data/entities/projectiles/deck/touch_gold.xml': ['TOUCH_GOLD'],
  'data/entities/projectiles/deck/touch_water.xml': ['TOUCH_WATER'],
  'data/entities/projectiles/deck/touch_oil.xml': ['TOUCH_OIL'],
  'data/entities/projectiles/deck/touch_alcohol.xml': ['TOUCH_ALCOHOL'],
  'data/entities/projectiles/deck/touch_blood.xml': ['TOUCH_BLOOD'],
  'data/entities/projectiles/deck/touch_smoke.xml': ['TOUCH_SMOKE'],
  'data/entities/projectiles/deck/destruction.xml': ['DESTRUCTION'],
  'data/entities/projectiles/deck/explosion.xml': ['EXPLOSION'],
  'data/entities/projectiles/deck/explosion_light.xml': ['EXPLOSION_LIGHT'],
  'data/entities/projectiles/deck/fireblast.xml': ['FIRE_BLAST'],
  'data/entities/projectiles/deck/poison_blast.xml': ['POISON_BLAST'],
  'data/entities/projectiles/deck/alcohol_blast.xml': ['ALCOHOL_BLAST'],
  'data/entities/projectiles/deck/thunder_blast.xml': ['THUNDER_BLAST'],
  'data/entities/projectiles/deck/berserk_field.xml': ['BERSERK_FIELD'],
  'data/entities/projectiles/deck/polymorph_field.xml': ['POLYMORPH_FIELD'],
  'data/entities/projectiles/deck/chaos_polymorph_field.xml': [
    'CHAOS_POLYMORPH_FIELD',
  ],
  'data/entities/projectiles/deck/electrocution_field.xml': [
    'ELECTROCUTION_FIELD',
  ],
  'data/entities/projectiles/deck/freeze_field.xml': ['FREEZE_FIELD'],
  'data/entities/projectiles/deck/regeneration_field.xml': [
    'REGENERATION_FIELD',
  ],
  'data/entities/projectiles/deck/teleportation_field.xml': [
    'TELEPORTATION_FIELD',
  ],
  'data/entities/projectiles/deck/levitation_field.xml': ['LEVITATION_FIELD'],
  'data/entities/projectiles/deck/shield_field.xml': ['SHIELD_FIELD'],
  'data/entities/projectiles/deck/projectile_transmutation_field.xml': [
    'PROJECTILE_TRANSMUTATION_FIELD',
  ],
  'data/entities/projectiles/deck/projectile_thunder_field.xml': [
    'PROJECTILE_THUNDER_FIELD',
  ],
  'data/entities/projectiles/deck/projectile_gravity_field.xml': [
    'PROJECTILE_GRAVITY_FIELD',
  ],
  'data/entities/projectiles/deck/vacuum_powder.xml': ['VACUUM_POWDER'],
  'data/entities/projectiles/deck/vacuum_liquid.xml': ['VACUUM_LIQUID'],
  'data/entities/projectiles/deck/vacuum_entities.xml': ['VACUUM_ENTITIES'],
  'data/entities/projectiles/deck/sea_lava.xml': ['SEA_LAVA'],
  'data/entities/projectiles/deck/sea_alcohol.xml': ['SEA_ALCOHOL'],
  'data/entities/projectiles/deck/sea_oil.xml': ['SEA_OIL'],
  'data/entities/projectiles/deck/sea_water.xml': ['SEA_WATER'],
  'data/entities/projectiles/deck/sea_acid.xml': ['SEA_ACID'],
  'data/entities/projectiles/deck/sea_acid_gas.xml': ['SEA_ACID_GAS'],
  'data/entities/projectiles/deck/cloud_water.xml': ['CLOUD_WATER'],
  'data/entities/projectiles/deck/cloud_oil.xml': ['CLOUD_OIL'],
  'data/entities/projectiles/deck/cloud_blood.xml': ['CLOUD_BLOOD'],
  'data/entities/projectiles/deck/cloud_acid.xml': ['CLOUD_ACID'],
  'data/entities/projectiles/deck/cloud_thunder.xml': ['CLOUD_THUNDER'],
  'data/entities/projectiles/deck/xray.xml': ['X_RAY'],
  'data/entities/projectiles/deck/ocarina/ocarina_a.xml': ['OCARINA_A'],
  'data/entities/projectiles/deck/ocarina/ocarina_b.xml': ['OCARINA_B'],
  'data/entities/projectiles/deck/ocarina/ocarina_c.xml': ['OCARINA_C'],
  'data/entities/projectiles/deck/ocarina/ocarina_d.xml': ['OCARINA_D'],
  'data/entities/projectiles/deck/ocarina/ocarina_e.xml': ['OCARINA_E'],
  'data/entities/projectiles/deck/ocarina/ocarina_f.xml': ['OCARINA_F'],
  'data/entities/projectiles/deck/ocarina/ocarina_gsharp.xml': [
    'OCARINA_GSHARP',
  ],
  'data/entities/projectiles/deck/ocarina/ocarina_a2.xml': ['OCARINA_A2'],
  'data/entities/projectiles/deck/kantele/kantele_a.xml': ['KANTELE_A'],
  'data/entities/projectiles/deck/kantele/kantele_d.xml': ['KANTELE_D'],
  'data/entities/projectiles/deck/kantele/kantele_dis.xml': ['KANTELE_DIS'],
  'data/entities/projectiles/deck/kantele/kantele_e.xml': ['KANTELE_E'],
  'data/entities/projectiles/deck/kantele/kantele_g.xml': ['KANTELE_G'],
  'data/entities/projectiles/deck/all_nukes.xml': ['ALL_NUKES'],
  'data/entities/projectiles/deck/all_discs.xml': ['ALL_DISCS'],
  'data/entities/projectiles/deck/all_rockets.xml': ['ALL_ROCKETS'],
  'data/entities/projectiles/deck/all_deathcrosses.xml': ['ALL_DEATHCROSSES'],
  'data/entities/projectiles/deck/all_blackholes.xml': ['ALL_BLACKHOLES'],
  'data/entities/projectiles/deck/all_acid.xml': ['ALL_ACID'],
  'data/entities/projectiles/deck/summon_portal.xml': ['SUMMON_PORTAL'],
  'data/entities/projectiles/deck/meteor_rain.xml': ['METEOR_RAIN'],
  'data/entities/projectiles/deck/worm_rain.xml': ['WORM_RAIN'],
  'data/entities/projectiles/deck/fireworks/firework_pink.xml': ['FIREWORK'],
  'data/entities/projectiles/deck/fireworks/firework_green.xml': ['FIREWORK'],
  'data/entities/projectiles/deck/fireworks/firework_blue.xml': ['FIREWORK'],
  'data/entities/projectiles/deck/fireworks/firework_orange.xml': ['FIREWORK'],
  'data/entities/items/pickup/egg_monster.xml': ['SUMMON_EGG'],
  'data/entities/items/pickup/egg_slime.xml': ['SUMMON_EGG'],
  'data/entities/items/pickup/egg_red.xml': ['SUMMON_EGG'],
  'data/entities/items/pickup/egg_fire.xml': ['SUMMON_EGG'],
};
